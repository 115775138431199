<template>
  <v-container class="container--fluid grid-list-md">
    <v-progress-circular
        :width="10"
        :size="80"
        indeterminate
        v-if="getLoading"
    ></v-progress-circular>
    <v-card class="px-5 pb-5" v-if="!getLoading">
      <v-form v-model="valid" ref="form">
        <div class="d-flex align-center">
          <v-btn
              x-small
              color="primary"
              outlined
              dark
              fab
              @click="$router.go(-1)"
              class="mr-3"
              title="Назад"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-btn
              outlined
              small
              color="success"
              @click="saveProd"
              class="mx-5"
          >{{ $t('btns.save') }}
          </v-btn>
          <v-btn
              icon
              dark
              @click="dialogDelete = !dialogDelete"
              :title="$t('btns.delete')"
              color="red"
              v-if="prodId !== 'new'"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-dialog
              v-model="dialogDelete"
              width="500"
          >
            <v-card class="pa-3">
              <p class="text-center"><b>{{ $t('btns.delete') }}?</b></p>
              <span>"{{ product.name }}"</span>
              <div class="d-flex justify-center">
                <v-btn text color="red" @click="deleteProd(product.id)">{{ $t('btns.delete') }}</v-btn>
                <v-btn text color="orange" @click="dialogDelete=false">{{ $t('btns.cancel') }}</v-btn>
              </div>
            </v-card>
          </v-dialog>


          <v-switch
              class="ml-10"
              color="success"
              v-model="product.active"
              :label='product.active? "Активно": "Неактивно"'
          ></v-switch>
        </div>
        <div class="d-flex align-start mb-2">
          <v-text-field
              label="Название"
              v-model="product.name"
              :rules="[rules.required, rules.counter]"
          ></v-text-field>
          <div class="ml-10">
            <div>
              <p class="p-name">Код / Код товара / Артикул</p>
            </div>
            <v-text-field
                :value="product.code"
                disabled=""
                solo
                dense
                hide-details="false"
            ></v-text-field>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <p class="p-name">Категория</p>
          </div>
          <v-autocomplete
              class="col-md-4 col-sm-6"
              solo
              dense
              multiple
              v-model="category"
          ></v-autocomplete>
        </div>
        <v-tabs
            class="mb-1"
            v-model="tabs"
            background-color="blue-grey lighten-5"
            height="35"
        >
          <v-tab>Цена/Учет</v-tab>
          <v-tab>Галерея</v-tab>
          <v-tab>Характеристики</v-tab>
          <v-tab>Описание</v-tab>
        </v-tabs>
        <div v-if="tabs === 0">
          <v-row class="px-2">
            <v-col cols="12" md="6" lg="4">
              <div>
                <div class="d-flex">
                  <p class="p-name">Цена</p>
                  <v-icon
                      class="mt-md-n1 ml-1"
                      title="информация"
                      small
                  >mdi-information
                  </v-icon>
                </div>
                <v-text-field
                    solo
                    dense
                    v-model="product.price"
                ></v-text-field>
              </div>
              <div>
                <div class="d-flex">
                  <p class="p-name">Старая цена</p>
                  <v-icon
                      class="mt-md-n1 ml-1"
                      title="информация"
                      small
                  >mdi-information
                  </v-icon>
                </div>
                <v-text-field
                    solo
                    dense
                    v-model="product.price_old"
                ></v-text-field>
              </div>
              <div>
                <div class="d-flex">
                  <p class="p-name">Оригинальная цена</p>
                  <v-icon
                      class="mt-md-n1 ml-1"
                      title="информация"
                      small
                  >mdi-information
                  </v-icon>
                </div>
                <v-text-field
                    solo
                    dense
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div>
                <div class="d-flex">
                  <p class="p-name">Количество</p>
                  <v-icon
                      class="mt-md-n1 ml-1"
                      title="информация"
                      small
                  >mdi-information
                  </v-icon>
                </div>
                <v-text-field
                    solo
                    dense
                    v-model="product.qty"
                ></v-text-field>
              </div>
              <div>
                <div class="d-flex">
                  <p class="p-name">Наличие</p>
                  <v-icon
                      class="mt-md-n1 ml-1"
                      title="информация"
                      small
                  >mdi-information
                  </v-icon>
                </div>
                <v-select
                    solo
                    dense
                    v-model="product.status_id"
                    :items="$store.state.helper.statusList"
                    item-text="text"
                    item-value="id"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="tabs === 2" class="px-2 pt-3">
          <div class="col-md-5 col-lg-4 mb-n5 pa-0">
            <div class="d-flex">
              <p class="p-name">Вендор</p>
              <v-icon
                  class="mt-md-n1 ml-1"
                  title="информация"
                  small
              >mdi-information
              </v-icon>
            </div>
            <v-text-field
                v-model="product.vendor"
                solo
                dense
            ></v-text-field>
          </div>

          <div v-for="param in params" class="d-flex justify-space-between align-end mt-2">
            <p class="mr-10">{{ param.name }}</p>
            <v-combobox
                dense
                hide-details
                :value="param"
                item-text="value"
                item-value="value_id"
                :items="listValuesParam"
                @click="getValuesParam(param)"
            ></v-combobox>
          </div>
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      block
                      class="text-none"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >Характеристики продавца
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list style="height: 100px">
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5" lg="5">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      block
                      class="text-none"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >Доступные характеристики ROZETKA
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list style="height: 100px">
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      block
                      class="text-none"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >Все характеристики
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list style="height: 100px">
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>

        <div v-if="tabs === 3">
          <vue-editor v-model="product.description" :editorToolbar="customToolbar"></vue-editor>
        </div>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProductCard",
  components: {VueEditor},
  data() {
    return {
      dialogDelete: false,
      valid: false,
      rules: {
        required: value => !!value && value.length >= 3 || 'Поле не должно быть быть пустым, не  менее 3 символов',
        counter: value => !!value && value.length <= 255 || 'Не более 256 символов',
      },
      originalProduct: {},
      product: {},
      category: [],
      params: [],
      listValuesParam: [],
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline", "strike"],
        [
          {align: ""},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ],
        [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
        [{indent: "-1"}, {indent: "+1"}],
        [{color: []}, {background: []}],
        ["clean"]
      ],
      description: '',
      switch1: true,
      tabs: null
    }
  },
  mounted() {
    if (this.prodId !== 'new') {
      this.createProductCard()
    }
  },
  computed: {
    ...mapGetters(["getCurrentProduct", "getLoading", "getParamsCats"]),
    prodId() {
      return this.$route.params.id;
    },
    categoryId() {
      return this.$route.params.category;
    }
  },
  watch: {
    prodId() {
      if (this.prodId === 'new') {
        this.product = {}
      }
    },
  },
  methods: {
    ...mapActions(["currentProduct", "updateProduct", "deleteProduct", "saveProduct", "initParamsCats"]),
    createProductCard() {
      let data = {
        id: this.prodId,
        params: "categories,params,paramsLinks,values"
      }
      this.currentProduct(data).then(function () {
        this.getApiProduct()
        this.originalProduct = Object.assign({}, this.product);

      }.bind(this))
    },
    getApiProduct() {
      let response = this.getCurrentProduct
      this.product = {
        ...response.translations[0],
        ...response
      }
      for (let param of this.product.params) {
        let value = this.product.values.find(p => p.paramid === param.id)
        let obj = {
          id: param.id,
          name: param.translations[0].param,
          value: value.translations[0].value,
          value_id: value.id
        }
        this.params.push(obj)
      }
      this.category.push(this.product.categories[0].translations[0].name)
    },
    deepEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
    saveProd() {
      if (this.prodId === 'new') {
        if (this.$refs.form.validate()) {
          let data = {
            category: this.categoryId,
            product: this.product
          }
          this.saveProduct(data).then(function (response) {
            if (response.status === 1) {
              this.$router.push(`/product/${this.categoryId}/${response.data.id}`)
              this.createProductCard()
            }
          }.bind(this))

        }
      } else {
        let equally = this.deepEqual(this.product, this.originalProduct)
        if (equally === false) {
          if (this.$refs.form.validate()) {
            let data = {
              id: this.prodId,
              product: this.product
            }
            this.updateProduct(data).then(function () {
              this.createProductCard()
            }.bind(this))

          }
        }
      }
    },
    deleteProd(id) {
      this.deleteProduct(id).then(function (response) {
        if (response.status === 1) {
          this.dialogDelete = false
          this.$router.go(-1)
        } else {
          console.log("error")
        }
      }.bind(this))
    },
    getValuesParam(value) {
      this.listValuesParam = []
      this.initParamsCats(value.id).then(function () {
       let values = this.getParamsCats.items[0].values
        for(let value of values){
          this.listValuesParam.push(value.translations[0])
        }
      }.bind(this))
    }
  },
}
</script>

<style scoped>

</style>
